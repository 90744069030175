<template>
  <div class="h100">
    <div class="cardWrap">
      <div
        :class="[{ nonProduct: !isProduct }, ['card']]"
        v-for="(item, index) in data"
        :key="index"
      >
        <div
          v-if="isProduct"
          :class="[
            productCompanyGroupName(item),
            { showBrandFooter: item.parentId },
            'imgWrap',
          ]"
        >
          <div v-if="item.addons" class="strip">
            <span>โปรโมชั่น</span>
          </div>
          <img
            v-if="item.productPhotos"
            :src="
              item.productPhotos.length > 0
                ? item.productPhotos[0].url
                : $placeholderImg
            "
            loading="lazy"
          />
          <img v-else :src="$placeholderImg" />
        </div>
        <div class="detailWrap">
          <div>{{ isCash ? "ส่วนลดเงินสด" : item.name }}</div>
          <div v-if="isProduct" class="priceWrap">
            <span class="price">
              <span v-if="calStep(item)"
                >{{ calStep(item).min.toLocaleString() }} ~
                {{ calStep(item).max.toLocaleString() }}</span
              >
              <span v-else-if="item.salePrice">{{
                item.salePrice.toLocaleString()
              }}</span>
            </span>
            <span class="unitPrice">บาท</span>
            <span v-if="item.price !== item.salePrice" class="fromPrice"
              >{{ item.price }} <span class="unitPrice">บาท</span></span
            >
            <span class="unit"> / {{ item.unit }}</span>
          </div>
        </div>
        <div class="actionContain">
          <div class="actionWrap" v-if="item.product_status !== 'สินค้าหมด'">
            <div class="buyBtn" @click.stop="clickButton(item)">เพิ่ม</div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="() => $emit('scrollEnd')"
      :loader-disable="fetching || endList || error"
      :loader-size="0"
      class="reset"
    >
    </scroll-loader>
    <ProductListLoader v-if="fetching" />
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <div v-if="data.length > 0" style="height: 100px"></div>
  </div>
</template>

<script>
import ProductListLoader from "@/components/loader/ProductListLoader";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import { calStep } from "@/utils/product";

export default {
  props: {
    data: Array,
    fetching: Boolean,
    endList: Boolean,
    error: Boolean,
    nodata: Boolean,
    mode: String,
    useModal: {
      default: true,
    },
    type: String,
  },
  components: {
    ProductListLoader,
    DefaultDisplayStage,
  },
  computed: {
    isCategory() {
      return this.type === "categories";
    },
    isBrand() {
      return this.type === "brands";
    },
    isProduct() {
      return this.type === "products";
    },
    isCash() {
      return this.type === "cash";
    },
  },
  methods: {
    calStep: calStep,
    clickCard(item) {
      this.$emit("clickCard", item);
    },
    clickButton(item) {
      this.$emit("clickButton", { item, type: this.type });
    },
  },
  mounted() {
    // document.body.classList.remove("modal-open");
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productCardList.scss";

.nonProduct {
  min-height: 80px;
}
</style>