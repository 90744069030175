<template>
  <div class="cardWrap">
    <div class="card" v-for="index in 6" :key="index">
      <div class="skeleton" aria-busy="true">
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <rect
              width="100%"
              height="100%"
              rx="2"
              fill="white"
              fill-opacity="1"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // let svg = document.getElementById("mask").outerHTML;
    // let sk = document.querySelector(".skeleton");
    // let encodedString = btoa(svg);
    // sk.setAttribute(
    //   "style",
    //   " -webkit-mask-image: url('data:image/svg+xml;base64," +
    //     encodedString +
    //     "')"
    // );
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/productCardList.scss";
@import "~@/styles/skeleton.scss";

.card {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  height: 220px;
  border: none;
}
</style>