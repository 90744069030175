
<template>
  <div class="conditionSection">
    <div class="head">
      <NavBar
        :backOnlyEvent="true"
        @back="$emit('back')"
        :showBack="true"
        :title="typeConfig[type].title"
      >
        <template #left-section>
          <div @click="openAddCondition = true" class="btn addBtn">+ เพิ่ม</div>
        </template>
      </NavBar>
    </div>
    <div class="statusMsg" v-if="formData.length === 0">กรุณาเพิ่มข้อมูล</div>
    <ValidationObserver ref="form">
      <div class="productList" ref="productList">
        <div class="item" v-for="(item, i) in formData" :key="i">
          <div class="productDetail">
            <div class="wrapImg">
              <img v-if="item.image" :src="item.image" width="70px" />
            </div>
            <div class="delete" @click.stop="removeItem(i)">
              <img src="@/assets/img/trash.svg" alt="image" />
            </div>
            <div class="wrapRight" v-if="item.name">{{ item.name }}</div>
          </div>
          <div class="detailSection">
            <div>
              <div>{{ typeConfig[type].typeLabel }}</div>
              <div>{{ NameTranslate(item.type) }}</div>
            </div>
            <div>
              <div>{{ typeConfig[type].unitLabel }}</div>
              <div>
                <ValidationProvider
                  :name="'type_' + i"
                  :rules="`required|oneOf:${typeConfig[type].optionsType[
                    item.type
                  ]
                    .map((e) => e[0])
                    .join()}`"
                  v-slot="{ errors }"
                >
                  <select v-model="item.unit">
                    <option
                      :value="type[0]"
                      v-for="type of typeConfig[type].optionsType[item.type]"
                      :key="type[0]"
                    >
                      {{ type[1] }}
                    </option>
                  </select>
                  <div class="errorMsg">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <div>{{ typeConfig[type].volumeLabel }}</div>
              <div>
                <ValidationProvider
                  :name="'volume_' + i"
                  rules="required|min_value:1"
                  v-slot="{ errors }"
                >
                  <input type="number" v-model.number="item.volume" />
                  <div class="errorMsg">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="actionBottom">
        <div class="btn saveBtn" @click="save()">บันทึก</div>
      </div>
    </ValidationObserver>

    <AddItemSection
      v-if="openAddCondition"
      @back="addConditionSectionBack"
      :open="openAddCondition"
      @close="openAddCondition = false"
      @add="addItem"
      :type="type"
    />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import AddItemSection from "./AddItemSection";
import NameTranslate from "../utils/nameTranslate";
import { extend } from "vee-validate";
import { required, min_value, oneOf } from "vee-validate/dist/rules";
import { PUT } from "@/utils/http";

// Override the default message.
extend("required", {
  ...required,
  message: "กรุณากรอกข้อมูลให้ถูกต้อง",
});

extend("min_value", {
  ...min_value,
  params: ["min"],
  message: "ค่าต้องไม่น้อยกว่า {min}",
});

extend("oneOf", {
  ...oneOf,
  message: "กรุณาเลือกข้อมูลให้ถูกต้อง",
});

export default {
  props: { open: Boolean, data: Array, id: String, type: String },
  components: { NavBar, AddItemSection },
  data: () => ({
    openAddCondition: false,
    list: [{ id: 1, name: "test" }],
    formData: [],
    // optionsType: {
    //   category: [["total", "ยอดรวม"]],
    //   brand: [["total", "ยอดรวม"]],
    //   product: [
    //     ["qty", "จำนวน"],
    //     ["total", "ยอดรวม"],
    //   ],
    // },
    typeConfig: {
      conditions: {
        title: "เงื่อนไขโปรโมชั่น",
        typeLabel: "ประเภทเงื่อนไข",
        unitLabel: "ประเภทเป้าหมาย",
        volumeLabel: "จำนวนเป้าหมาย",
        optionsType: {
          category: [["total", "ยอดรวม"]],
          brand: [["total", "ยอดรวม"]],
          product: [
            ["qty", "จำนวน"],
            ["total", "ยอดรวม"],
          ],
        },
      },
      premiums: {
        title: "ประโยชน์ที่ได้รับ",
        typeLabel: "ประเภทสิทธิประโยชน์",
        unitLabel: "หน่วย",
        volumeLabel: "จำนวนที่ได้รับ",
        optionsType: {
          cash: [["amount", "จำนวนเงิน"]],
          product: [["qty", "จำนวน"]],
        },
      },
    },
  }),
  watch: {
    data: {
      immediate: true,
      deep: true,
      async handler() {
        this.formData = this.data;
      },
    },
  },
  computed: {},
  methods: {
    NameTranslate,
    addItem(e) {
      console.log("addItem", e);
      if (this.formData.some((it) => it.refId == e.refId && it.type == e.type))
        return;
      if (e.type === "cash" && this.formData.some((it) => it.type === "cash"))
        return;
      this.formData.push(e);
    },
    removeItem(i) {
      this.formData.splice(i, 1);
    },
    addConditionSectionBack() {
      this.openAddCondition = false;
    },
    async save() {
      const formSuccess = await this.$refs.form.validate();
      if (!formSuccess) {
        window.$alert("กรุณากรอกข้อมูลให้ครบและถูกต้อง");
        return;
      }

      if (!(await window.$alert("ต้องการบันทึกหรือไม่?", "confirm"))) {
        return;
      }

      window.loading(true);

      const payloadData = this.formData.map(
        ({ refId, type, unit, volume }) => ({
          refId,
          type,
          unit,
          volume,
        })
      );

      const { status } = await PUT(
        `/api/shops/${this.$myShop.id}/promotions/${this.id}`,
        {
          [this.type]: payloadData,
        }
      );

      window.loading(false);

      if (status !== 200) {
        await window.$alert("ไม่สามารถดำเนินการได้ขณะนี้");
      }

      this.$emit("back");
    },
  },
};
</script>
<style lang="scss" scoped>
.conditionSection {
  background: #fff;
  position: fixed;
  padding-top: 40px;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .title {
    margin: 20px 0 0 20px;
    font-size: 24px;
    padding: 10px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .addBtn {
    background: var(--primary-color);
    color: #fff;
    margin: 0;
    padding: 8px 0;
    margin-left: auto;
    width: 67px;
    font-size: 13px;
  }

  .productList {
    padding: 10px;
    > .item {
      position: relative;
      width: 100%;
      max-width: 600px;
      margin: 10px auto;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      border-radius: 15px;
      border: 1px solid #ddd;

      &:last-child {
        margin-bottom: 100px !important;
      }

      .wrapImg {
        margin-right: 7px;
      }

      .delete {
        position: absolute;
        width: 20px;
        right: 10px;
        top: 10px;
        img {
          width: 100%;
        }
      }

      .wrapRight {
        padding: 10px;
        padding-right: 35px;
        font-size: 16px;
      }

      .priceWrap {
        .fromPrice {
          font-size: 12px;
          margin-left: 6px;
          color: #a5a5a5;
          text-decoration: line-through;
        }

        .price {
          font-size: 13px;
          padding-right: 4px;
          color: var(--primary-color);
        }
      }
      .head {
        display: flex;
        justify-content: space-between;
      }
      .productDetail {
        display: flex;
      }
      .detailSection {
        padding: 10px;

        > div {
          flex: 1 1 auto;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 3px 0;
          align-items: flex-start;
          > div:nth-child(1) {
            flex: 0 0 137px;
            margin-top: 2px;
          }
          > div:nth-child(2) {
            flex: 1;
            min-width: 100px;
          }
        }
      }
    }
  }

  // select {
  //   color: rgb(68, 68, 68);
  //   background: white;
  // }

  select,
  input,
  textarea {
    padding: 3px 8px;
    width: 100%;
    font-size: 16px;
    max-width: 400px;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 8px;
    color: rgb(63, 63, 63);
    display: block;
    margin: 0 auto;
  }

  .actionBottom {
    padding: 0 15px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
  }

  .saveBtn {
    background: var(--primary-color);
    color: #fff;
  }

  .errorMsg {
    margin: 4px 0 0 8px;
    color: crimson;
  }

  .statusMsg {
    height: 100%;
    margin-top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>
