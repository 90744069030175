var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h100"},[_c('div',{staticClass:"cardWrap"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,class:[{ nonProduct: !_vm.isProduct }, ['card']]},[(_vm.isProduct)?_c('div',{class:[
          _vm.productCompanyGroupName(item),
          { showBrandFooter: item.parentId },
          'imgWrap',
        ]},[(item.addons)?_c('div',{staticClass:"strip"},[_c('span',[_vm._v("โปรโมชั่น")])]):_vm._e(),(item.productPhotos)?_c('img',{attrs:{"src":item.productPhotos.length > 0
              ? item.productPhotos[0].url
              : _vm.$placeholderImg,"loading":"lazy"}}):_c('img',{attrs:{"src":_vm.$placeholderImg}})]):_vm._e(),_c('div',{staticClass:"detailWrap"},[_c('div',[_vm._v(_vm._s(_vm.isCash ? "ส่วนลดเงินสด" : item.name))]),(_vm.isProduct)?_c('div',{staticClass:"priceWrap"},[_c('span',{staticClass:"price"},[(_vm.calStep(item))?_c('span',[_vm._v(_vm._s(_vm.calStep(item).min.toLocaleString())+" ~ "+_vm._s(_vm.calStep(item).max.toLocaleString()))]):(item.salePrice)?_c('span',[_vm._v(_vm._s(item.salePrice.toLocaleString()))]):_vm._e()]),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")]),(item.price !== item.salePrice)?_c('span',{staticClass:"fromPrice"},[_vm._v(_vm._s(item.price)+" "),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")])]):_vm._e(),_c('span',{staticClass:"unit"},[_vm._v(" / "+_vm._s(item.unit))])]):_vm._e()]),_c('div',{staticClass:"actionContain"},[(item.product_status !== 'สินค้าหมด')?_c('div',{staticClass:"actionWrap"},[_c('div',{staticClass:"buyBtn",on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item)}}},[_vm._v("เพิ่ม")])]):_vm._e()])])}),0),_c('scroll-loader',{staticClass:"reset",attrs:{"loader-method":() => _vm.$emit('scrollEnd'),"loader-disable":_vm.fetching || _vm.endList || _vm.error,"loader-size":0}}),(_vm.fetching)?_c('ProductListLoader'):_vm._e(),_c('DefaultDisplayStage',{attrs:{"error":_vm.error,"nodata":_vm.nodata}}),(_vm.data.length > 0)?_c('div',{staticStyle:{"height":"100px"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }