
<template>
  <div class="addConditionSection">
    <div class="head">
      <NavBar
        :backOnlyEvent="true"
        @back="$emit('back')"
        :showBack="true"
        :title="typeConfig[type].title"
      >
      </NavBar>
      <div :class="'collapse'">
        <Menu @change="menuChange" :menuData="typeConfig[type].menuData" />
        <!-- <Filters
          v-if="showFilters"
          v-on:filters="updatefilters"
          :list="filtersList"
        /> -->
      </div>
    </div>
    <div :class="[{ hasFilters: showFilters }, 'content']">
      <!-- <keep-alive> -->
      <!-- <div style="height: 30px"></div> -->
      <ItemList
        :data="data"
        :fetching="loading"
        :endList="endList"
        :error="error"
        :nodata="!loading && !error && data.length === 0"
        @scrollEnd="getData()"
        :type="typeKey"
        @clickButton="clickItemButton"
      />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Menu from "./Menu";
import ItemList from "./ItemList";
import { GET } from "@/utils/http";

export default {
  props: { type: String },
  components: { NavBar, Menu, ItemList },
  data() {
    return {
      data: [],
      page: 1,
      loading: false,
      error: false,
      endList: false,
      typeKey: "",
      typeConfig: {
        conditions: {
          title: "เพิ่มเงื่อนไข",
          typeLabel: "ประเภทเงื่อนไข",
          unitLabel: "ประเภทเป้าหมาย",
          volumeLabel: "จำนวนเป้าหมาย",
          menuData: [
            {
              name: "หมวดหมู่",
              key: "categories",
            },
            {
              name: "แบรนด์",
              key: "brands",
            },
            {
              name: "สินค้า",
              key: "products",
            },
          ],
        },
        premiums: {
          title: "เพิ่มประโยชน์ที่ได้รับ",
          typeLabel: "ประเภทสิทธิประโยชน์",
          unitLabel: "หน่วย",
          volumeLabel: "จำนวนที่ได้รับ",
          menuData: [
            {
              name: "ส่วนลดเงินสด",
              key: "cash",
            },
            {
              name: "สินค้า",
              key: "products",
            },
          ],
        },
      },
    };
  },
  computed: {
    showFilters() {
      return this.$route.meta.filters === true;
    },
  },
  methods: {
    clickItemButton(e) {
      console.log(e);
      const type = {
        categories: "category",
        brands: "brand",
        products: "product",
        cash: "cash",
      }[e.type];

      this.$emit("add", {
        type,
        ...(e.item.id && { refId: e.item.id?.toString() }),
        name: e.item.name,
        unit: "",
        volume: 0,
        image: e.item.productPhotos?.[0]?.url || null,
      });

      this.$emit("back");
    },
    menuChange(e) {
      this.typeKey = e;
      this.data = [];
      this.page = 1;
      this.endList = false;
      this.getData(e);
    },
    async getData(e) {
      console.log("getData", e);

      if (this.typeKey === "cash") {
        this.data = [{ type: "cash" }];
        return;
      }
      this.loading = true;
      this.error = false;

      const { status, body } = await GET(
        `/api/${this.typeKey}?page=${this.page}&limit=5&shopId=${this.$myShop.id}`
      );

      this.loading = false;

      if (status !== 200) {
        this.error = true;
        return;
      }

      this.data.push(...body.data);

      if (this.page >= body.lastPage) {
        this.endList = true;
      } else {
        this.page++;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.addConditionSection {
  background: #fff;
  position: fixed;
  padding-top: 78px;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .title {
    margin: 20px 0 0 20px;
    font-size: 24px;
    padding: 10px;
  }

  // .content {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 80vh;
  // }
  .collapse {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 45px;
    transition: top 0.3s;
  }
  .collapse.hide {
    top: -95px;
  }

  .content {
    height: 100%;
    // overflow-y: auto;
    // height: 90vh;
    &.hasFilters {
      padding: 110px 0 70px 0;
    }
  }

  .addBtn {
    background: var(--primary-color);
    color: #fff;
    margin: 0;
    padding: 8px 0;
    margin-left: auto;
    width: 95px;
    font-size: 13px;
  }
}
</style>
