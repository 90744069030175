const data = {
  category: { th: "หมวดหมู่" },
  brand: { th: "แบรนด์" },
  product: { th: "สินค้า" },
  qty: { th: "จำนวน" },
  total: { th: "ยอดรวม" },
  cash: { th: "ส่วนลดเงินสด" },
  amount: { th: "จำนวนเงิน" },
};

export default function(name, lang = "th") {
  return data[name]?.[lang] || name || null;
}
