<template>
  <div class="menuBar">
    <div
      v-for="menu in menuList"
      :key="menu.key"
      @click="clickMenu(menu.key)"
      :class="{ active: selectedMenu == menu.key }"
    >
      {{ menu.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: { menuData: Array },
  data() {
    return {
      selectedMenu: "categories",
      menuList: [],
    };
  },
  methods: {
    clickMenu(val) {
      this.selectedMenu = val;
      this.$emit("change", val);
    },
  },
  beforeMount() {
    this.menuList = this.menuData;
  },
  mounted() {
    this.clickMenu(this.menuList[0].key);
  },
};
</script>
<style lang="scss" scoped>
.menuBar {
  user-select: none;
  display: flex;
  background: #ffffff;
  justify-content: center;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  > div {
    width: 100%;
    text-align: center;
    color: #55585b;
    font-size: clamp(12px, 3.5vw, 13px);
    padding: 3px 2px 10px 2px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 4px;
    cursor: pointer;
    &.active {
      position: relative;
    }
  }
}

.active:after {
  content: "";
  width: 80%;
  border-bottom: 2px solid var(--primary-color) !important;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}
</style>
