<template>
  <div class="root">
    <NavBar :showBack="true" title="รายละเอียดโปรโมชั่น" />
    <div class="wrap">
      <div class="head">
        <div>ข้อมูลโปรโมชั่น</div>
        <div
          class="btn editBtn"
          @click="$router.push('/settings/promotion/edit/' + id)"
        >
          แก้ไขข้อมูล
        </div>
      </div>
      <div class="dataBox">
        <div class="sideFlex">
          <div class="left">
            <div class="group">
              <b>ชื่อโปรโมชั่น </b><span>{{ data.name }}</span>
            </div>
            <div class="group">
              <b> วันที่เริ่ม </b><span>{{ toDate(data.startTime) }}</span>
            </div>
            <div class="group">
              <b> วันที่สิ้นสุด</b> <span>{{ toDate(data.endTime) }}</span>
            </div>
            <div class="group activeSection">
              <b>การเปิดใช้งาน </b>
              <span class="active" v-if="data.active">เปิดใช้งาน</span>
              <span class="inActive" v-if="!data.active">ปิดใช้งาน</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="head">
        <div>เงื่อนไขโปรโมชั่น</div>
        <div class="btn editBtn" @click="openSection('conditions')">
          + แก้ไขเงื่อนไข
        </div>
      </div>
      <div class="dataBox">
        <div v-if="data.conditions.length === 0" class="statusMsg">
          ไม่มีข้อมูล
        </div>

        <div v-for="(con, i) in data.conditions" :key="con.id">
          <div>
            <img v-if="con.image" class="itemImage" src="" alt="image" />
            {{ con.name }}
          </div>
          <div class="sideFlex">
            <div class="left">
              <div class="group">
                <b>ประเภทเงื่อนไข </b>
                <span> {{ NameTranslate(con.type) }}</span>
              </div>

              <div class="group">
                <b> จำนวนเป้าหมาย </b><span>{{ con.volume }}</span>
              </div>
            </div>
            <div class="right">
              <div class="group">
                <b> ประเภทเป้าหมาย</b>
                <span> {{ NameTranslate(con.unit) }}</span>
              </div>
            </div>
          </div>
          <div v-if="i < data.conditions.length - 1" class="line"></div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="head">
        <div>ประโยชน์ที่ได้รับ</div>
        <div class="btn editBtn" @click="openSection('premiums')">
          + แก้ไขประโยชน์ที่ได้รับ
        </div>
      </div>
      <div class="dataBox">
        <div v-if="data.premiums.length === 0" class="statusMsg">
          ไม่มีข้อมูล
        </div>
        <div v-for="(pm, i) in data.premiums" :key="pm.id">
          <div>
            <img v-if="pm.image" class="itemImage" src="" alt="image" />
            {{ pm.name }}
          </div>
          <div class="sideFlex">
            <div class="left">
              <div class="group">
                <b> ประเภทสิทธิประโยชน์</b>
                <span> {{ NameTranslate(pm.type) }}</span>
              </div>

              <div class="group">
                <b> จำนวนที่ได้รับ</b> <span>{{ pm.volume }}</span>
              </div>
            </div>
            <div class="right">
              <div class="group">
                <b> หน่วย</b> <span>{{ NameTranslate(pm.unit) }}</span>
              </div>
            </div>
          </div>
          <div v-if="i < data.premiums.length - 1" class="line"></div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="btn deleteBtn" @click="deletePromotion()">ลบ</div>
      <!-- <div class="btn saveBtn">บันทึก</div> -->
    </div>
    <ConfigSectionManage
      v-if="typeSection"
      @back="closeSection()"
      :data="dataSection"
      :type="typeSection"
      :id="id"
    />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import ConfigSectionManage from "./configSection/Manage";
import { GET, DELETE } from "@/utils/http";
import NameTranslate from "./utils/nameTranslate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// Override the default message.
extend("required", {
  ...required,
  message: "กรุณากรอกข้อมูลให้ถูกต้อง",
});
export default {
  components: { NavBar, ConfigSectionManage },
  props: { id: String },
  data: () => ({
    data: {},
    typeSection: null,
    dataSection: "",
  }),
  methods: {
    NameTranslate,
    closeSection() {
      this.typeSection = null;
      this.getData();
    },
    openSection(type) {
      this.dataSection = this.data[type];
      this.typeSection = type;
    },
    toDate(val) {
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        year: "numeric",
        month: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(new Date(val));
    },
    async getData() {
      this.loading = true;
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/promotions/${this.id}`
      );

      this.loading = false;

      if (status !== 200) {
        this.error = true;
        return;
      }

      this.data = body;
    },
    async deletePromotion() {
      if (!(await window.$alert("ต้องการลบหรือไม่?", "confirm"))) {
        return;
      }

      this.loading = true;
      const { status } = await DELETE(
        `/api/shops/${this.$myShop.id}/promotions/${this.id}`
      );
      this.loading = false;
      if (status !== 200) {
        await window.$alert("ไม่สามารถดำเนินการได้ขณะนี้");
      }
      this.$router.back();
    },
  },
  mounted() {
    this.id && this.getData();
  },
};
</script>

<style lang="scss" scoped>
.root {
  padding-top: 50px;
}

.itemImage {
  width: 10%;
  aspect-ratio: 1;
  object-fit: cover;
}

.head {
  margin: 4px 0;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    width: initial;
    font-size: 13px;
    margin: 0;
  }
}

.wrap {
  padding: 6px 15px;
}

.dataBox {
  width: 100%;
  max-width: 600px;
  font-size: 14px;
  margin: 0 auto;
  line-height: 20px;
  overflow: hidden;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ddd;

  .sideFlex {
    display: flex;
    justify-content: space-between;
    .left {
      padding-right: 5px;
    }
  }

  .group {
    b {
      color: rgb(70, 70, 70);
      font-size: 13px;
    }
    margin: 5px 0;
  }

  .wrapFirst,
  .actionBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    .editBtn {
      color: rgb(148, 148, 148);
      font-weight: bold;
      border: 2px solid rgb(218, 218, 218);
      padding: 4px 10px;
      border-radius: 5px;
    }
  }
}

.errorMsg {
  margin: 10px;
  color: crimson;
  font-size: 14px;
}

.editBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 0px auto;
  max-width: 400px;
  padding: 8px;
}

.saveBtn {
  background: var(--primary-color);
  color: #fff;
}

.deleteBtn {
  border: 1px solid crimson;
  background: #fff;
  color: crimson;
}

.line {
  margin: 0px auto 8px auto;
  padding: 3px 0;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.statusMsg {
  margin: 20px auto;
  text-align: center;
}

.activeSection {
  .active {
    color: #38761d;
  }

  .inActive {
    color: coral;
  }
}
</style>